import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { borderRadius, colorTokens, desktopMediaQuery, RowContainerV2, spacing, Text } from '@orus.eu/pharaoh'
import { ContractProduct } from '@orus.eu/pharaoh/src/components/features/multi-contracts'
import { memo } from 'react'
import { SmallScreenMargin } from '../../../templates/small-screen-margin'
import type { UserContractsByActivitiesAndAddresses } from '../../contracts-by-activity-and-address'
import { productsAvatarConfigurations } from '../../subscription-v2/elements/quote-page-body-element/product-information-util'

type ContractsByActivitiesAndAddressesProps = {
  userContractsByActivitiesAndAddresses: UserContractsByActivitiesAndAddresses[]
  hideLegalProtection?: boolean | undefined
  displayContractNumber: boolean
  hideCurrentProtectionStatus?: boolean | undefined
  onContractClick: (subscriptionId: string, productName: string) => void
  activityVariant: 'subtitle2' | 'body2Medium'
}

export const ContractsByActivitiesAndAddresses = memo<ContractsByActivitiesAndAddressesProps>(
  function ContractsByActivityAndAddress(props) {
    const {
      userContractsByActivitiesAndAddresses,
      hideLegalProtection,
      displayContractNumber,
      hideCurrentProtectionStatus,
      onContractClick,
      activityVariant,
    } = props

    return userContractsByActivitiesAndAddresses.map((userContractsByActivityAndAddress) => {
      return (
        <div key={userContractsByActivityAndAddress.activity.displayName}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <SmallScreenMargin>
              <Text variant={activityVariant}>{userContractsByActivityAndAddress.activity.displayName}</Text>
              <Text
                variant="body2"
                color={colorTokens['color-text-base-basic']}
                css={css`
                  margin-top: ${spacing[20]};
                  margin-bottom: ${spacing[50]};
                `}
              >
                {userContractsByActivityAndAddress.companyAddress}
              </Text>
            </SmallScreenMargin>

            <GuaranteeListContainer variant="mobile">
              {userContractsByActivityAndAddress.contracts &&
                userContractsByActivityAndAddress.contracts.map((contract) => {
                  return (
                    contract.productsInformation &&
                    contract.productsInformation.map((productInformation) => {
                      if (hideLegalProtection && productInformation.product === 'rcph-pj') return
                      return (
                        <ContractProduct
                          key={productInformation.product}
                          productInformation={productInformation}
                          productsAvatarConfigurations={productsAvatarConfigurations[productInformation.product]}
                          currentProtectionStatus={
                            hideCurrentProtectionStatus ? undefined : contract.currentProtectionStatus
                          }
                          contractNumber={contract.contractNumber}
                          displayContractNumber={displayContractNumber}
                          onClick={() => onContractClick(contract.subscriptionId, productInformation.name)}
                        />
                      )
                    })
                  )
                })}
            </GuaranteeListContainer>
          </div>
        </div>
      )
    })
  },
)

const GuaranteeListContainer = styled(RowContainerV2)`
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  border: 0;
  border-top: 1px solid ${colorTokens['color-stroke-base']};
  border-bottom: 1px solid ${colorTokens['color-stroke-base']};

  ${desktopMediaQuery} {
    border-radius: ${borderRadius[20]};
    border: 1px solid ${colorTokens['color-stroke-base']};
  }
`
